<template>
    <div class="container">
        <h1 class="text-center">{{ $t('title') }}</h1>
        
        
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-center">
                </div>
                <div class="col-12 c-card-wrap">
                    <b-card>
                        <h1 class="text-center">{{ $t('registration.reg') }}</h1>
                        <b-form class="login" autocomplete=”off” @submit.prevent="registration">
                            <b-form-group
                                label-for="email">
                                <b-form-input
                                    v-model="email"
                                    id="email"
                                    :placeholder="$t('registration.email')"
                                    required
                                    type="email"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                label-for="password">
                                <b-form-input
                                    autocomplete="new-password"
                                    v-model="password"
                                    id="password"
                                    :placeholder="$t('registration.pswd')"
                                    required
                                    type="password"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                label-for="password_heslo">
                                <b-form-input
                                    v-model="password_test"
                                    id="password_heslo"
                                    :placeholder="$t('registration.pswdTest')"
                                    required
                                    type="password"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-button class="col-12 " type="submit" variant="primary">{{$t('registration.regBtn')}}</b-button>
                            </b-form-group>
                            <router-link :to="{name: 'homepage'}" class=""><b>{{$t('registration.login')}}</b></router-link>
                        </b-form>
                    </b-card>
                </div>
            </div>
        </div>
    
    
    </div>
</template>

<script>
export default {
    name: "registration",
    data() {
        return {
            email: "",
            password: "",
            password_test: ""
        }
    },
    methods: {
        async registration() {
            
            if (this.password !== this.password_test) {
                this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.pswd_not_same'))
                return false;
            }
            
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/users/registration.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {'email': this.email, 'password': this.password},
                    method: 'PUT'
                });
                const data = response.data;
                await this.$router.push('/homepage');
                this.$eventHub.$emit('openMessageController', 'success', data.message);
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message)
            }
        }
    }
}
</script>

<style scoped>

</style>
